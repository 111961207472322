import styled, { css } from 'styled-components';
import { webColors, grid } from 'styles';
import { uiColors } from '@aceandtate/ds';

export type SwatchStyleProps = {
  styleProps: {
    image?: string;
    hasError?: boolean;
    isCurrentColor?: boolean;
    size?: 'small' | 'medium';
    color?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
};

export const MaterialOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ImageTag = styled.div<SwatchStyleProps>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--color-picker-swatch-size);
  justify-content: center;
  width: var(--color-picker-swatch-size);
  ${props =>
    props.styleProps.color &&
    css`
      background: ${props.styleProps.color};
    `}

  &:before {
    content: '';
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    height: calc(var(--color-picker-swatch-size) + 2px);
    width: calc(var(--color-picker-swatch-size) + 2px);
    ${props =>
      props.styleProps.isLoading &&
      css`
        background-color: ${uiColors.light};
      `}
    transition: background-color 0.2s ease-in;
  }

  overflow: hidden;
  background-size: cover;
  background-position: center;

  ${props =>
    props.styleProps.isCurrentColor &&
    css`
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #3126e6, 0 0 0px 6px #e6e8f2;
    `};
`;

export const SwatchWrapper = styled.a<SwatchStyleProps>`
  ${getSizeStyles};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding: var(--color-picker-swatch-grid-gap-size);
  position: relative;
  width: fit-content;

  ${props =>
    props.styleProps.isDisabled &&
    css`
      pointer-events: none;
    `}

  img {
    border-radius: 50%;
    height: var(--color-picker-swatch-image-size);
    width: var(--color-picker-swatch-image-size);
  }

  ${props =>
    !props.styleProps.isCurrentColor &&
    css`
      &:hover {
        ${ImageTag} {
          box-shadow: 0 0 0 3px #fff, 0 0 0 4px ${webColors.highlightPrimaryDark};
        }
      }
    `}
`;

function getSizeStyles(props: SwatchStyleProps) {
  const { size, isCurrentColor } = props.styleProps;
  const isSmall = size === 'small';
  const isCurrent = isCurrentColor;
  const isCurrentGap = isSmall ? '2px' : '4px';

  return css`
    --color-picker-swatch-size: ${isSmall ? '16px' : '24px'};
    --color-picker-swatch-gap-size: ${isCurrent ? isCurrentGap : '0px'};
    --color-picker-swatch-hover-gap-size: 2px;
    --color-picker-swatch-grid-gap-size: calc(${grid.s} / 2);
    --color-picker-swatch-border-gap-size: ${isSmall ? '0px' : '4px'};
    --color-picker-swatch-image-size: calc(var(--color-picker-swatch-size) - var(--color-picker-swatch-gap-size));
    --color-picker-swatch-image-hover-size: calc(
      var(--color-picker-swatch-size) - var(--color-picker-swatch-hover-gap-size)
    );

    &:hover {
      --color-picker-swatch-gap-size: ${isCurrent ? '0px' : '2px'};
    }
  `;
}
