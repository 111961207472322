import { Variant } from 'types/torii';
import { IntlShape } from 'react-intl';
import { GENDER } from 'globalConstants';

import messages from './messages';

export const getHeadImg = (product: Variant, gender: string, randomSeed?: 0 | 1) => {
  switch (gender) {
    case GENDER.WOMEN:
      return product.images.female?.url || product.images.unisex?.url;
    case GENDER.MEN:
      return product.images.male?.url || product.images.unisex?.url;
    default:
      // When both show a random
      if (product.images.female?.url && product.images.male?.url) {
        const random = randomSeed || Math.floor(Math.random() * 2 + 1);
        return random === 1 ? product.images.male?.url : product.images.female?.url;
      }
      if (!product.images.female?.url && !product.images.male?.url) {
        return product.images.dynamic?.url;
      }
      if (!product.images.dynamic?.url) {
        return null;
      }
      // Otherwise proritise system
      return product.images.male?.url || product.images.female?.url || product.images.unisex?.url;
  }
};

// TODO add considerations for limited editions back in once
// torii api supports the field
export const getLabel = (product: Variant, intl: IntlShape) => {
  if (!product.availability.isAvailableOnline) {
    return intl.formatMessage(messages.outOfStock);
    // return product.limited_edition
    //   ? intl.formatMessage(messages.soldOut)
    //   : intl.formatMessage(messages.outOfStock);
  }
  if (product.availability.lowStockOnline) {
    return intl.formatMessage(messages.lowStock);
  }
  // if (product.limited_edition) {
  //   return intl.formatMessage(messages.limitedEdition);
  // }
  // if (product.is_new) {
  //   return intl.formatMessage(messages.isNew);
  // }
  return null;
};

export const getThumbSrc = (id: string) => `//images.aceandtate.com/image/upload/ar_0.8,c_crop/c_scale,w_100/${id}`;

export const getImageSrc = (id: string) => `//images.aceandtate.com/image/upload/c_scale,w_1200/${id}`;
