import React from 'react';

interface Props {
  color: { secondary: string; tertiary: string };
}

export default function ThreeColorAcetate({ color }: Props) {
  return (
    <svg width='100%' viewBox='0 0 48 48'>
      <use href='/static/swatches/three-color-acetate.svg#acetate-tertiary' fill={color.tertiary} />
      <use href='/static/swatches/three-color-acetate.svg#acetate-secondary' fill={color.secondary} />
    </svg>
  );
}
