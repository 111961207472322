import React from 'react';
import { usePath } from 'paths';
import * as Styles from './styles';
import type { ColorProps, SwatchModel } from 'types/colorSwatches';
import TwoColorAcetate from './patterns/TwoColorAcetate';
import ThreeColorAcetate from './patterns/ThreeColorAcetate';

type SwatchProps = {
  color: ColorProps;
  isCurrentColor?: boolean;
  onColorClick?: (color: ColorProps) => void;
  onHover?: (color: ColorProps | null) => void;
  matchedColor?: SwatchModel | null;
  size?: 'small' | 'medium';
  isDisabled?: boolean;
  isLoading?: boolean;
};

export default function Swatch(props: SwatchProps) {
  const {
    color,
    isCurrentColor = false,
    size,
    onHover,
    onColorClick,
    matchedColor,
    isDisabled = false,
    isLoading = false
  } = props;

  const pathTo = usePath();

  function onClickHandler(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    onColorClick(color);
  }

  const styleProps = {
    color: null,
    image: color.image,
    isCurrentColor,
    isDisabled,
    isLoading,
    size
  };

  if (matchedColor) {
    switch (matchedColor.pattern) {
      case 'gradient':
        if (matchedColor.color.tertiary) {
          styleProps.color = `linear-gradient(${matchedColor.color.primary}, ${matchedColor.color.secondary}, ${matchedColor.color.tertiary})`;
        } else {
          styleProps.color = `linear-gradient(${matchedColor.color.primary}, ${matchedColor.color.secondary})`;
        }
        break;
      case 'duotone':
        styleProps.color = `linear-gradient(45deg, ${matchedColor.color.primary} 50%, ${matchedColor.color.secondary} 50%)`;
        break;
      default:
        styleProps.color = matchedColor.color.primary;
        break;
    }
  }

  return (
    <Styles.SwatchWrapper
      onMouseEnter={() => onHover(color)}
      onMouseLeave={() => onHover(null)}
      onClick={e => {
        e.stopPropagation();
        onClickHandler(e);
      }}
      href={pathTo(color.href)}
      aria-label={color.name}
      styleProps={styleProps}
      data-testid={`swatch-color-${color.name}`}
    >
      <Styles.ImageTag data-testid='color-picker-dot' styleProps={styleProps}>
        {matchedColor && matchedColor.pattern === 'acetate' && (
          <Styles.MaterialOverlay>
            {matchedColor.color.tertiary ? (
              <ThreeColorAcetate color={{ ...matchedColor.color }} />
            ) : (
              <TwoColorAcetate color={matchedColor.color.secondary} />
            )}
          </Styles.MaterialOverlay>
        )}
      </Styles.ImageTag>
    </Styles.SwatchWrapper>
  );
}
