import styled from 'styled-components';
import * as Types from '../types';

export const ImageContainer = styled.div<Types.ContainerProps>`
  width: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  cursor: pointer;

  :before {
    display: block;
    content: '';
    padding-top: 100%;
  }
`;

export const RelativeImageContainer = styled.div`
  position: relative;
  padding-bottom: 100%;
  height: 100%;
  width: 100%;
`;

export const RelativeHoverImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const ImageHoverContainer = styled.div`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.2s ease-in;
  transition-delay: 0.1s;

  :hover {
    opacity: 100;
  }
`;
