import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { differenceInDays, parseISO } from 'date-fns';
import { brandColors, breakpointRules, Flex, Typography, useMediaQuery } from '@aceandtate/ds';
import { WishlistButton, WishlistAction } from 'features/Wishlist';
import { calculateRoundedPercentageDiscount } from 'utils/helpers/cart';
import { DEAL_LABEL_COUNTRIES } from 'globalConstants';
import ColorPicker from 'components/ColorPicker';
import Tag from 'components/Tag';
import ecommerceMessages from 'messages/ecommerce';
import giftcardMessages from 'messages/giftcard';
import ProductImage from './partials/ProductImage';
import * as Styles from './styles';
import messages from './messages';
import priceDisplay from 'messages/priceDisplay';
import { ProductCardProps } from './types';
import DraftModeProductTag from 'components/DraftModeNotice/DraftModeProductTag';
import { isArchiveSale, isKidsGlasses } from 'utils/product/productTaxonomies';
import type { ProductVariant } from 'types/torii';
import { ServicesContext } from 'services/context';
import { webColors } from 'styles';
function alignToFlexMap(align: ProductCardProps['titleAlign']) {
  switch (align) {
    case 'center':
      return 'center';
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'space-between';
  }
}

export default function ProductCard(props: ProductCardProps) {
  const {
    product,
    gender,
    variants = [],
    hasColorPicker,
    hasLabel = true,
    productType,
    hasPrice = true,
    hoverImageType,
    mainImageType,
    titleAlign,
    hasWishlist,
    onClick,
    disableHref,
    onVariantClick,
    viewType = 'pcp'
  } = props;

  const isTablet = useMediaQuery(breakpointRules.tablet);

  const [visibleProduct, setVisibleProduct] = useState(product);
  const intl = useIntl();
  const { locale } = useContext(ServicesContext);

  const isGiftCard = productType === 'giftcard';
  const isSunny = product.filterOptions.types.includes('sunny');
  const changeActiveColor = color => {
    const variant = variants.find(x => x.id === color.id);
    setVisibleProduct(variant);
    onVariantClick && onVariantClick(variant);
  };

  const getLabel = useMemo((): ReactElement => {
    //
    if (visibleProduct.availability.isAvailableOnline === false || visibleProduct.availability.isTradeInStoreOnly) {
      return product.displayAttributes.isLimitedEdition ? (
        <Tag>
          <FormattedMessage {...messages.soldOut} />
        </Tag>
      ) : (
        <Tag>
          <FormattedMessage {...messages.outOfStock} />
        </Tag>
      );
    }

    if (isArchiveSale(visibleProduct as ProductVariant)) {
      return (
        <Tag color={brandColors.accent} fill>
          <FormattedMessage {...messages.archive} />
        </Tag>
      );
    }

    if (visibleProduct.salePrice) {
      const percentOff =
        calculateRoundedPercentageDiscount(visibleProduct.price.value, visibleProduct.salePrice.value) + '%';

      return (
        <Tag color={webColors.sale} fill>
          {DEAL_LABEL_COUNTRIES.includes(locale.country) ? (
            <span>
              <FormattedMessage {...ecommerceMessages.deal} />
              &nbsp;
              {percentOff}
            </span>
          ) : (
            <span>
              <FormattedMessage {...ecommerceMessages.sale} />
              &nbsp;
              {percentOff}
            </span>
          )}
        </Tag>
      );
    }

    if (isKidsGlasses(visibleProduct as ProductVariant)) {
      return (
        <Tag>
          <FormattedMessage {...messages.kids} />
        </Tag>
      );
    }

    if (visibleProduct.availability.lowAvailabilityOnline) {
      return (
        <Tag>
          <FormattedMessage {...messages.lowStock} />
        </Tag>
      );
    }
    if (visibleProduct.displayAttributes.isLimitedEdition) {
      return (
        <Tag>
          <FormattedMessage {...messages.limitedEdition} />
        </Tag>
      );
    }

    const daysSinceRelease = differenceInDays(new Date(), parseISO(visibleProduct.availability.availableFromDate));
    if (daysSinceRelease < 30) {
      return (
        <Tag>
          <FormattedMessage {...messages.isNew} />
        </Tag>
      );
    }
    return null;
  }, [product, visibleProduct]);

  const colors = variants
    .filter(x => x.filterOptions.types.includes('sunny') === isSunny && x.images.colorSwatch?.url)
    .map(x => ({
      href: '',
      id: x.id,
      image: x.images.colorSwatch.url,
      isAvailableOnline: x.availability.isAvailableOnline,
      name: x.displayAttributes.color,
      sku: x.sku,
      visibility: x.visibility
    }));

  const showColorPicker = hasColorPicker && !isGiftCard && colors.length > 0;

  const isDraftMode = visibleProduct.draftMode;
  const currentColor = colors.find(color => color.id === visibleProduct.id);

  return (
    <Styles.Container
      data-testid={`product-card_container-${visibleProduct.sku}`}
      onClick={() => {
        onClick && onClick();
        onVariantClick && onVariantClick(visibleProduct);
      }}
    >
      <Styles.LabelContainer>
        <ProductImage
          mainImageType={mainImageType}
          hoverImageType={hoverImageType}
          disableHref={disableHref}
          product={visibleProduct}
          gender={gender}
          loading={props.loading}
          data-testid='product-card_image'
          productType={productType}
        />
        {hasLabel && getLabel && <Styles.TagContainer>{getLabel}</Styles.TagContainer>}
        {hasWishlist && (
          <Styles.WishlistButtonWrapperV1>
            <WishlistAction item={visibleProduct} view={viewType} productType={productType}>
              {actionProps => (
                <WishlistButton
                  {...actionProps}
                  SvgProps={{ color: brandColors.dark75, fontSize: 24 }}
                  ButtonProps={{ color: 'light', size: 'small', variant: 'default' }}
                  variant={isTablet ? 'default' : 'icon'}
                />
              )}
            </WishlistAction>
          </Styles.WishlistButtonWrapperV1>
        )}
        {isDraftMode && (
          <Styles.PositionVariantDraftModeTag>
            <DraftModeProductTag />
          </Styles.PositionVariantDraftModeTag>
        )}
      </Styles.LabelContainer>
      <Styles.ContentContainerV1>
        <Flex flexDirection='column' alignItems={alignToFlexMap(titleAlign)}>
          <Styles.ProductInfoWrapper
            justifyContent='space-between'
            alignItems='baseline'
            gap={4}
            $titleAlign={titleAlign}
          >
            <Styles.Title variant='h5' serif data-testid='product-card_title' textAlign={titleAlign}>
              {isGiftCard ? intl.formatMessage(giftcardMessages.productTitle) : visibleProduct.name}
            </Styles.Title>
            {hasPrice && (
              <Styles.CurrentPrice
                variant='h6'
                isSale={!!visibleProduct.salePrice}
                isAccent={isArchiveSale(visibleProduct)}
              >
                {isGiftCard ? (
                  <FormattedMessage
                    {...priceDisplay.priceLowercase}
                    values={{ price: visibleProduct.salePrice?.display || visibleProduct.price.display }}
                  />
                ) : (
                  visibleProduct.salePrice?.display || visibleProduct.price.display
                )}
              </Styles.CurrentPrice>
            )}
          </Styles.ProductInfoWrapper>
          {!showColorPicker && !!isGiftCard && (
            <Typography variant='bodyS' gutterBottom>
              {visibleProduct.displayAttributes.color}
            </Typography>
          )}
          <Flex justifyContent={showColorPicker ? 'space-between' : 'flex-end'} style={{ width: '100%' }}>
            {showColorPicker && (
              <ColorPicker
                align={titleAlign}
                size='small'
                onColorClick={changeActiveColor}
                colors={colors}
                currentColor={currentColor}
              />
            )}
            {hasPrice && visibleProduct.salePrice && (
              <Styles.MarkdownPrice>{visibleProduct.price.display}</Styles.MarkdownPrice>
            )}
          </Flex>
        </Flex>
      </Styles.ContentContainerV1>
    </Styles.Container>
  );
}
