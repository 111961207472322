import { uiColors } from '@aceandtate/ds';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import * as Styles from './styles';

export default function DraftModeProductTag() {
  return (
    <Styles.DraftModeTagWrap variant='bodyS' forwardedAs='span'>
      <FormattedMessage {...messages.draftModeProduct} />

      <svg height='.8em' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill={uiColors.warning} />
      </svg>
    </Styles.DraftModeTagWrap>
  );
}
