import { defineMessages } from 'react-intl';

export default defineMessages({
  including_prescription: {
    defaultMessage: 'Incl. prescription lenses',
    id: 'pricedisplay.includingPrescription'
  },
  non_prescription: {
    defaultMessage: 'Without prescription lenses',
    id: 'pricedisplay.sunglasses.nonPrescription'
  },
  price: {
    defaultMessage: 'From {price}',
    id: 'pricedisplay.price'
  },
  priceLowercase: {
    defaultMessage: 'from {price}',
    id: 'pricedisplay.price.lowercase'
  }
});
