import useSWR, { SWRConfiguration } from 'swr';

import { SwatchModel } from 'types/colorSwatches';

export function useColorSwatchesService() {
  const publicUrl = `${process.env.NEXT_PUBLIC_ECOMM_ENDPOINT_BASE}/public/color-swatches`;
  const options: SWRConfiguration = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  };

  const { data, error } = useSWR(
    publicUrl,
    async (url: string) => {
      const data: SwatchModel[] | [] = await fetch(url).then(res => res.json());
      return data;
    },
    options
  );

  return {
    colorSwatches: data,
    loadingColorSwatches: !error && !data
  };
}
