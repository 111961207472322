import React, { useEffect, useState } from 'react';
import { brandColors } from '@aceandtate/ds';
import { useColorSwatchesService } from 'services/colorSwatchesService';
import { SwatchModel, ColorProps } from 'types/colorSwatches';
import Swatch from 'components/Swatch';
import * as Styles from './styles';
import { CSSProperties } from 'styled-components';

type ColorPickerProps = {
  colors: ColorProps[];
  currentColor: ColorProps;
  onColorClick: (color: ColorProps) => void;
  size?: 'small' | 'medium';
  ref?: React.RefObject<HTMLDivElement>;
  colorPickerRef?: React.RefObject<HTMLDivElement>;
  align?: CSSProperties['textAlign'];
};

function parseColor(str: string) {
  const filenameIndex = str.lastIndexOf('/');
  const filename = str.substr(filenameIndex);
  return filename.replace(/\.jpg|\.png|\//gi, '');
}

export default function ColorPicker(props: ColorPickerProps) {
  const { colorSwatches, loadingColorSwatches } = useColorSwatchesService();
  const { colors, currentColor, onColorClick, size, colorPickerRef, align } = props;

  const [displayedColor, setDisplayedColor] = useState(currentColor);
  const displayedColorName = displayedColor ? displayedColor.name : '';

  // TODO: Restore temporarily disabled online only flags while we launch v2 frames
  // const onlineOnly = !currentColor?.visibility.includes('pos');
  const onlineOnly = false; // eslint-disable-line

  useEffect(() => {
    setDisplayedColor(currentColor);
  }, [currentColor]);

  if (!colors || !colors.find(color => color === currentColor)) return null;

  function handleHover(color: ColorProps | null) {
    setDisplayedColor(color || currentColor);
  }

  return (
    <Styles.ColorPickerContainer ref={colorPickerRef}>
      <Styles.ColorName
        textAlign={align}
        variant='bodyM'
        data-testid='swatch-displayed-color'
        color={brandColors.dark75}
      >
        {displayedColorName}
      </Styles.ColorName>
      <Styles.SwatchesContainer
        styleProps={{
          align,
          isCurrentColor: currentColor === displayedColor,
          size
        }}
      >
        {colors.map((color: ColorProps) => {
          const colorName = parseColor(color.image);
          return (
            <Swatch
              key={color.sku}
              color={color}
              matchedColor={
                colorName && colorSwatches
                  ? colorSwatches?.find((swatch: SwatchModel) => swatch.name === colorName)
                  : null
              }
              isCurrentColor={currentColor === color}
              isLoading={loadingColorSwatches}
              onColorClick={onColorClick}
              onHover={handleHover}
              size={size}
            />
          );
        })}
      </Styles.SwatchesContainer>
    </Styles.ColorPickerContainer>
  );
}
