import { Typography, grid } from '@aceandtate/ds';
import styled, { CSSProperties, css } from 'styled-components';
import { webColors } from 'styles';

export const Row = styled.div`
  display: flex;
  flex-flow: row;
`;

export const ColorName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;

  ${({ textAlign }) =>
    textAlign !== 'center' &&
    textAlign !== 'right' &&
    css`
      margin-right: ${grid[8]};
    `}
`;

export const ColorPickerContainer = styled.div`
  height: 100%;
  white-space: nowrap;
`;

export const SwatchesContainer = styled.div<SwatchStyleProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${({ styleProps }) => {
    switch (styleProps.align) {
      case 'right':
        return css`
          transform: translateX(3px);
        `;
      case 'center':
        return css``;
      default:
        return css`
          transform: translateX(-3px);
        `;
    }
  }}
`;

export type SwatchStyleProps = {
  styleProps: {
    image?: string;
    hasError?: boolean;
    isCurrentColor?: boolean;
    size?: 'small' | 'medium';
    color?: string;
    isDisabled?: boolean;
    align?: CSSProperties['textAlign'];
  };
};

export const MaterialOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ImageTag = styled.div<SwatchStyleProps>`
  align-items: center;
  border-radius: 50%;
  background-color: ${webColors.medium};
  display: flex;
  height: var(--color-picker-swatch-size);
  justify-content: center;
  transition: opacity 0.2s ease-in;
  width: var(--color-picker-swatch-size);
  background: url('${props => props.styleProps.image}');
  ${props =>
    props.styleProps.color &&
    css`
      background: ${props.styleProps.color};
    `}
  background-size: cover;
  background-position: center;
  overflow: hidden;
  ${props =>
    props.styleProps.isCurrentColor &&
    css`
      border-color: 1px solid transparent;
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px #3126e6, 0 0 0px 6px #e6e8f2;
    `};
`;

export const SwatchWrapper = styled.a<SwatchStyleProps>`
  ${getSizeStyles};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding: var(--color-picker-swatch-grid-gap-size);
  position: relative;
  width: fit-content;

  img {
    border-radius: 50%;
    height: var(--color-picker-swatch-image-size);
    width: var(--color-picker-swatch-image-size);
  }

  ${props =>
    !props.styleProps.isCurrentColor &&
    css`
      &:hover {
        ${ImageTag} {
          box-shadow: 0 0 0 3px #fff, 0 0 0 4px ${webColors.highlightPrimaryDark};
        }
      }
    `}
`;

function getSizeStyles(props: SwatchStyleProps) {
  const { size, isCurrentColor } = props.styleProps;
  const isSmall = size === 'small';
  const isCurrent = isCurrentColor;
  const isCurrentGap = isSmall ? '2px' : '4px';

  return css`
    --color-picker-swatch-size: ${isSmall ? '16px' : '24px'};
    --color-picker-swatch-gap-size: ${isCurrent ? isCurrentGap : '0px'};
    --color-picker-swatch-hover-gap-size: 2px;
    --color-picker-swatch-grid-gap-size: calc(${grid[8]} / 2);
    --color-picker-swatch-border-gap-size: ${isSmall ? '0px' : '4px'};
    --color-picker-swatch-image-size: calc(var(--color-picker-swatch-size) - var(--color-picker-swatch-gap-size));
    --color-picker-swatch-image-hover-size: calc(
      var(--color-picker-swatch-size) - var(--color-picker-swatch-hover-gap-size)
    );

    &:hover {
      --color-picker-swatch-gap-size: ${isCurrent ? '0px' : '2px'};
    }
  `;
}
