import styled, { CSSProp } from 'styled-components';
import { brandColors, Typography, grid, breakpointRules, Flex } from '@aceandtate/ds';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  color: inherit;
  position: relative;
  text-decoration: none;
  margin-top: ${grid[12]};
  padding-left: ${grid[8]};

  @media ${breakpointRules.laptop} {
    padding-left: 0;
  }
`;

export const ProductInfoWrapper = styled(Flex)<{ $titleAlign: CSSProp }>`
  line-height: initial;
  ${({ $titleAlign }) => $titleAlign === 'left' && 'width: 100%'};
`;

export const CurrentPrice = styled(Typography)<{ isSale?: boolean; isAccent?: boolean }>`
  white-space: nowrap;
  ${({ isSale = false, isAccent = false }) =>
    `color: ${isSale ? brandColors.sale : isAccent ? brandColors.accent : brandColors.dark75};`};
`;

export const MarkdownPrice = styled(Typography)`
  color: ${brandColors.dark75};
  text-decoration: line-through;
  white-space: nowrap;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
`;

export const WishlistButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -42px;
  right: ${grid[12]};

  @media ${breakpointRules.tablet} {
    top: 0;
    right: 0px;
  }
`;

export const PositionDraftModeTag = styled.div`
  position: absolute;
  top: -50px;
  left: ${grid[8]};
`;

export const PositionVariantDraftModeTag = styled.div`
  position: absolute;
  left: ${grid[8]};
  bottom: ${grid[8]};
`;

// VARIANTS
export const LabelContainer = styled.div`
  position: relative;
`;

export const ContentContainerV1 = styled(ContentContainer)`
  padding-inline: ${grid[8]};

  @media ${breakpointRules.laptop} {
    padding-inline: 0;
  }
`;
export const WishlistButtonWrapperV1 = styled.div`
  display: flex;
  position: absolute;
  bottom: ${grid[12]};
  right: ${grid[12]};

  @media ${breakpointRules.tablet} {
    top: ${grid[16]};
    right: ${grid[16]};
    bottom: unset;
  }
`;

export const TagContainer = styled.div`
  position: absolute;
  top: ${grid[8]};
  left: ${grid[8]};
  width: calc(100% - 65px); /* spacing for favorite icon (heart) */

  @media ${breakpointRules.tablet} {
    top: ${grid[16]};
    left: ${grid[16]};
    bottom: unset;
  }
`;
