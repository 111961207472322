import styled from 'styled-components';
import { grid, brandColors, Typography } from '@aceandtate/ds';
import React, { CSSProperties } from 'react';

type StyleProps = Partial<{
  color: CSSProperties['color'];
  fill: boolean;
}>;

const Tag = styled.div<{ $styleProps: StyleProps }>`
  padding: 2px ${grid[4]};
  background-color: ${({ $styleProps: { fill, color } }) => (fill ? color : brandColors.light)};
  color: ${({ $styleProps: { fill, color } }) => (fill ? brandColors.light : color || brandColors.dark)};
  border: 1px solid ${({ $styleProps: { color } }) => color || brandColors.dark75};
  width: fit-content;
`;

type Props = {
  children: React.ReactNode;
} & StyleProps;

export default function ({ children, color, fill }: Props) {
  return (
    <Tag $styleProps={{ color, fill }}>
      <Typography variant='h6' fontWeight='regular'>
        {children}
      </Typography>
    </Tag>
  );
}
