import React, { useMemo, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { ProductType, Variant } from 'types/torii';
import Link from 'next/link';
import { getHeadImg } from '../helpers';
import { getImageAltForProduct, getImageTitleForProduct } from 'utils/product/productImages';
import { getScaledSrc, getScaledAccessorySrc, getSkuHoverSrc } from 'utils/cloudinary';
import { usePath } from 'paths';
import * as Styles from './styles';
import { GENDER } from 'globalConstants';
import { breakpointRules } from 'styles';
import OptimizedCloudinaryImage from 'components/Images/OptimizedCloudinaryImage';
import { ProductCardProps } from '../types';

type ProductImageProps = {
  gender: string | undefined;
  product: Variant;
  productType: ProductType;
  disableHref?: boolean;
  'data-testid'?: string;
  loading?: 'eager' | 'lazy';
  hoverImageType?: ProductCardProps['hoverImageType'];
  mainImageType?: ProductCardProps['mainImageType'];
};

function getRandomSeed(): 0 | 1 {
  return Math.floor(Math.random() * 2 + 1) as 0 | 1;
}

const ProductImage = (props: ProductImageProps) => {
  const {
    hoverImageType = 'headshot',
    mainImageType = 'packshot',
    product,
    productType,
    gender,
    loading = 'lazy',
    disableHref
  } = props;

  const intl = useIntl();
  const pathTo = usePath();

  const skuPath =
    gender && gender !== GENDER.UNISEX && productType === 'frame'
      ? pathTo(`/${product.sku}?gender=${gender}`)
      : pathTo(`/${product.sku}`);

  // forces revalidation of images when hover changes
  // returns 0 or 1 which is passed to getHeadImg
  // only revalidates memo when the random value would result in a new image
  const [randomGenderSeed, dispatchGenderSeed] = useReducer(getRandomSeed, undefined);

  const images = useMemo(() => {
    const packshot = {
      loader: ({ src, width, quality }) =>
        productType === 'frame' || productType === 'clip_on'
          ? getScaledSrc(src, { quality, width })
          : getScaledAccessorySrc(src, { quality, width }),
      src: product.images.flat?.url || product.images.front?.url
    };

    const headshot = {
      loader: ({ src, width, quality }) => getSkuHoverSrc(src, { quality, width }),
      src: getHeadImg(product, gender, randomGenderSeed)
    };

    function getHoverImage() {
      switch (hoverImageType) {
        case 'packshot':
          return packshot;
        case 'none':
          return null;
        case 'headshot':
        default:
          return headshot;
      }
    }

    return {
      hoverImage: getHoverImage(),
      mainImage: mainImageType === 'headshot' ? headshot : packshot
    };
  }, [gender, product, hoverImageType, mainImageType, randomGenderSeed]);

  const sizes = `${breakpointRules.smallMax} 50vw, ${breakpointRules.largeMax} 33vw, 25vw`;
  const WrapperComponent = disableHref ? React.Fragment : Link;

  function onMouseLeave() {
    if (mainImageType === 'headshot' && hoverImageType === 'packshot') {
      dispatchGenderSeed();
    }
  }

  function onMouseEnter() {
    if (mainImageType === 'packshot' && hoverImageType === 'headshot') {
      dispatchGenderSeed();
    }
  }

  return (
    <WrapperComponent
      {...(disableHref ? {} : { href: skuPath })}
      {...(disableHref ? {} : { prefetch: false })}
      data-testid={`product-card_image-sku-${product.sku}`}
    >
      <Styles.ImageContainer {...{ onMouseEnter, onMouseLeave }}>
        {images.mainImage.src && (
          <Styles.RelativeImageContainer data-testid={props['data-testid']}>
            <OptimizedCloudinaryImage
              fill
              loading={loading}
              priority={loading === 'eager'}
              src={images.mainImage.src}
              sizes={sizes}
              quality={100}
              alt={getImageAltForProduct({ intl, productType, variant: product })}
              loader={images.mainImage.loader}
              title={getImageTitleForProduct(product, intl)}
              className='product-image-card'
            />
          </Styles.RelativeImageContainer>
        )}
        {images.hoverImage?.src && (
          <Styles.ImageHoverContainer>
            <Styles.RelativeHoverImageContainer data-testid={props['data-testid']}>
              <OptimizedCloudinaryImage
                src={images.hoverImage.src}
                fill
                onError={(e: any) => {
                  e.target.style.display = 'none';
                }}
                sizes={sizes}
                loader={images.hoverImage.loader}
                alt={getImageAltForProduct({ intl, productType, variant: product })}
                title={getImageTitleForProduct(product, intl)}
                className='model-image-card'
              />
            </Styles.RelativeHoverImageContainer>
          </Styles.ImageHoverContainer>
        )}
      </Styles.ImageContainer>
    </WrapperComponent>
  );
};

export default ProductImage;
