import { defineMessages } from 'react-intl';

const messages = defineMessages({
  isNew: {
    defaultMessage: 'New',
    id: 'skucard.label.isNew'
  },
  kids: {
    defaultMessage: 'Kids',
    id: 'skucard.label.kids'
  },
  limitedEdition: {
    defaultMessage: 'Limited edition',
    id: 'skucard.label.limitedEdition'
  },
  lowStock: {
    defaultMessage: 'Low stock',
    id: 'skucard.label.lowStock'
  },
  outOfStock: {
    defaultMessage: 'Out of stock',
    id: 'skucard.label.outOfStock'
  },
  soldOut: {
    defaultMessage: 'Sold out',
    id: 'skucard.label.soldOut'
  },
  archive: {
    defaultMessage: 'Archive',
    id: 'skucard.label.archive'
  }
});

export default messages;
