import React, { SVGProps } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { IconFavouriteFilled, IconFavourite, IconButton } from '@aceandtate/ds';
import { ButtonProps } from '@aceandtate/ds/dist/components/Button/Button';
import messages from './messages';
import type { RenderChildProps } from './types';

const HTMLButton = styled.button`
  all: unset;
  cursor: pointer;
  display: contents;
`;

type ActionProps = Pick<RenderChildProps, 'active' | 'onToggle'>;

type UIProps = {
  variant?: 'default' | 'icon';
  SvgProps?: SVGProps<SVGSVGElement>;
  ButtonProps?: ButtonProps;
};

export default function WishlistButton(props: ActionProps & UIProps) {
  const { variant = 'default', SvgProps, ButtonProps, active, onToggle } = props;

  const intl = useIntl();

  const Icon = active ? (
    <IconFavouriteFilled fontSize={27} {...SvgProps} />
  ) : (
    <IconFavourite fontSize={27} {...SvgProps} />
  );

  if (variant === 'icon') {
    return (
      <HTMLButton
        aria-label={intl.formatMessage(active ? messages.removeFromWishlist : messages.addToWishlist)}
        onClick={e => {
          e.stopPropagation();
          onToggle();
        }}
        {...ButtonProps}
      >
        {Icon}
      </HTMLButton>
    );
  }

  return (
    <IconButton
      aria-label={intl.formatMessage(active ? messages.removeFromWishlist : messages.addToWishlist)}
      onClick={e => {
        e.stopPropagation();
        onToggle();
      }}
      color='accent'
      variant={active ? 'default' : 'outlined'}
      {...ButtonProps}
    >
      {Icon}
    </IconButton>
  );
}
